import type { ConfigType } from '@markdoc/markdoc';

import { useFormState } from './useFormState';

const config: ConfigType = {
  tags: {
    textarea: {
      render: 'TextArea',
      attributes: {
        name: {
          type: String,
          required: true,
        },
        placeholder: {
          type: String,
          required: false,
        },
        maxLength: {
          type: Number,
          required: false,
        },
      },
    },
  },
};

interface TextAreaProps {
  name: string;
  maxLength?: number;
  placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  maxLength,
  placeholder,
}) => {
  const { componentState, setComponentState } = useFormState('textarea', name);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComponentState(event.target.value);
  };

  return (
    <div className="flex justify-center">
      <textarea
        name={name}
        value={componentState.value as string}
        maxLength={maxLength}
        onChange={handleChange}
        className="block rounded-md border-gray-300 bg-inherit shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm md:min-w-[30rem]"
        placeholder={placeholder}
      />
    </div>
  );
};

export { TextArea, config as textAreaConfig };
