import type { ConfigType } from '@markdoc/markdoc';
import React from 'react';

const config: ConfigType = {
  tags: {
    textAndText: {
      render: 'TextAndText',
      children: [],
    },
  },
};

interface TextAndTextProps {
  name: string;
  children?: React.ReactNode[];
}

const TextAndText: React.FC<TextAndTextProps> = ({ children }) => {
  let childrenWithNoBorder;
  const left: React.ReactNode[] = [];
  const right: React.ReactNode[] = [];
  let foundHR = false;
  if (children && children.length) {
    // Apply style to remove borders from all children
    childrenWithNoBorder = children.map((child) =>
      React.isValidElement(child)
        ? React.cloneElement(child as React.ReactElement<any>, {
            style: { border: 'none' },
          })
        : child
    );

    childrenWithNoBorder.map((child: any) => {
      if (child.type === 'hr') {
        foundHR = true;
        return null;
      }
      if (!foundHR) {
        left.push(child);
      } else {
        right.push(child);
      }
      return null;
    });
  }

  return (
    <div className="w-full">
      <div className="flex">
        <div>{left.map((l) => l)}</div>
        <div>{right.map((l) => l)}</div>
      </div>
    </div>
  );
};

export { TextAndText, config as textAndTextConfig };
