import type { ConfigType } from '@markdoc/markdoc';
import Vimeo from '@u-wave/react-vimeo';
import * as React from 'react';

import useWindowSize from '../../utils/useWindowSize';

const config: ConfigType = {
  tags: {
    video: {
      render: 'VideoPlayer',
      children: [],
      attributes: {
        video: {
          type: String,
          errorLevel: 'critical',
        },
      },
      selfClosing: true,
    },
  },
};

interface IProps {
  video: string;
}

const VimeoPlayer = ({ video }: IProps) => {
  const { width } = useWindowSize();
  return (
    <Vimeo
      video={video}
      className="mx-auto w-max"
      width={width}
      style={{ minHeight: '360px' }}
    />
  );
};

export { VimeoPlayer, config as vimeoPlayerConfig };
