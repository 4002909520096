import type { ConfigType } from '@markdoc/markdoc';
import React from 'react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

const config: ConfigType = {
  tags: {
    check: {
      render: 'Check',
      children: [],
    },
  },
};
interface CheckProps {
  children?: React.ReactNode[];
}

const Check: React.FC<CheckProps> = ({ children }) => {
  return (
    <div className="flex flex-nowrap items-baseline">
      <div className="mr-4" style={{ color: '#1C33AE' }}>
        <IoIosCheckmarkCircleOutline />
      </div>
      <div className="float-left font-semibold">{children}</div>
    </div>
  );
};

export { Check, config as CheckConfig };
