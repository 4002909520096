import 'react-datepicker/dist/react-datepicker.css';

import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import type { ConfigType } from '@markdoc/markdoc';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

export const SetReminderConfig: ConfigType = {
  tags: {
    setReminder: {
      render: 'SetReminder',
      attributes: {
        name: {
          type: String,
          required: true,
        },
        tomorrow: {
          type: Boolean,
          required: false,
        },
      },
    },
  },
};

interface SetReminderProps {
  name: string;
  tomorrow?: boolean;
}

const getDefaultDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  date.setHours(8, 0, 0, 0);
  return date;
};

export const SetReminder: React.FC<SetReminderProps> = ({
  name,
  tomorrow = false,
}: SetReminderProps) => {
  const pSlug = useProgramSlug();
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    tomorrow ? getDefaultDate() : null
  );

  const formatDateForGoogleCalendar = (date: Date) => {
    return date.toISOString().replace(/-|:|\.\d\d\d/g, '');
  };

  const generateGoogleCalendarUrl = () => {
    if (!selectedDate) return '';

    const startDate = formatDateForGoogleCalendar(selectedDate);
    const endDate = formatDateForGoogleCalendar(
      new Date(selectedDate.getTime() + 15 * 60 * 1000) // Adding 15 minutes
    );

    const recurrenceRule = `RRULE:FREQ=ONCE;BYHOUR=${selectedDate.getHours()};BYMINUTE=${selectedDate.getMinutes()}`;
    const params = new URLSearchParams({
      action: 'TEMPLATE',
      text: 'Weller Care: Treatment reminder',
      dates: `${startDate}/${endDate}`,
      details: `<a href="${window.location.origin}/p/${pSlug}/today/">Today Page</a>`,
      location: '',
      recur: recurrenceRule,
      trp: 'true',
    });

    return `https://calendar.google.com/calendar/u/0/r/eventedit?${params.toString()}`;
  };

  const handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeParts = e.target.value.split(':');
    const date = getDefaultDate();
    date.setHours(parseInt(timeParts[0], 10));
    date.setMinutes(parseInt(timeParts[1], 10));
    setSelectedDate(date);
  };

  return (
    <div id={name}>
      <div className="flex flex-col items-center">
        {tomorrow ? (
          <input
            type="time"
            defaultValue="08:00"
            onChange={handleTimeInput}
            className="mb-4"
          />
        ) : (
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showTimeSelect
            dateFormat="Pp"
            placeholderText="Select a date and time"
            className="mb-4"
          />
        )}
        <a
          href={generateGoogleCalendarUrl()}
          className="mt-2 inline-block rounded bg-blue-800 p-2 text-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          Add to Google Calendar
        </a>
      </div>
    </div>
  );
};
