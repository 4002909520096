import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import React from 'react';

const config: ConfigType = {
  tags: {
    div: {
      render: 'Div',
      children: [],
      attributes: {
        className: {
          type: String,
          required: false,
        },
        childClassName: {
          type: String,
          required: false,
        },
      },
    },
  },
};

interface DivProps {
  className?: string;
  childClassName?: string;
  children?: React.ReactNode[];
}

const Div: React.FC<DivProps> = ({ children, className, childClassName }) => {
  return (
    <div className={classNames('', className)}>
      {children && children.map
        ? children.map((child, i) => {
            return (
              <div key={i} className={childClassName}>
                {child}
              </div>
            );
          })
        : children}
    </div>
  );
};

export { Div, config as DivConfig };
