import type { ConfigType } from '@markdoc/markdoc';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { TextInput } from 'flowbite-react';
import Image from 'next/image';
import average from 'public/assets/images/average.png';
import bad from 'public/assets/images/bad.png';
import good from 'public/assets/images/good.png';
import great from 'public/assets/images/great.png';
import terrible from 'public/assets/images/terrible.png';
import React, { useEffect, useReducer, useState } from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { IoCheckmark } from 'react-icons/io5';

import { useProgram } from '../../app/(program)/course/program.gql';
import { useQueryState, useUpsertState } from '../../app/(program)/query';
import { ToolCalendar } from '../../components/calendar/Calendar';
import { SleepScheduleSlider } from '../../components/sleepScheduleSlider/SleepScheduleSlider';
import {
  formatDateString,
  generateFutureDates,
} from '../../utils/formatDateString';
import { useCreateProgramEvent } from '../analytics/useImpression';
import { useAuth } from '../authentication/useAuth';
import type { Interaction } from '../graphql/generated-types';
import { useFormState } from './useFormState';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

type State = {
  date: string;
  sleepSchedule: [number, number, number, number]; // [Bedtime, LightsOut, Awake, OutOfBed]
  fallAsleep: number; // number of minutes to fall asleep
  sleepInterruptions: number[]; // [<durationOfInterruption>, <durationOfInterruption>, ... ]
  sleepQualityA: string; // ??? should we track as a number, enum, or string?
  sleepQualityB: string; // ??? should we track as a number, enum, or string?
  sleepNotes: string; // anything the patient wants to note down
  daytimeNaps: number[]; // [<durationOfNaps>, <durationOfNaps>, ... ]
};

const initialState = (date?: Date) => {
  return {
    date: formatDateString(date || new Date()),
    sleepSchedule: [9.5, 10.5, 20, 21],
    fallAsleep: 0,
    sleepInterruptions: [],
    sleepQualityA: '',
    sleepQualityB: '',
    sleepNotes: '',
    daytimeNaps: [],
  } as State;
};

const sleepQualityALabels = [
  'Very restless',
  'Restless',
  'Average',
  'Sound',
  'Very sound',
];
const sleepQualityAIcons = [
  <Image src={terrible} alt={''} width={40} height={40} key={0} />,
  <Image src={bad} alt={''} width={40} height={40} key={1} />,
  <Image src={average} alt={''} width={40} height={40} key={2} />,
  <Image src={good} alt={''} width={40} height={40} key={3} />,
  <Image src={great} alt={''} width={40} height={40} key={4} />,
];

const sleepQualityBLabels = [
  'Worse than usual',
  'Same as usual',
  'Better than usual',
];

const sleepQualityBIcons = [
  <Image src={bad} alt={''} width={40} height={40} key={0} />,
  <Image src={average} alt={''} width={40} height={40} key={1} />,
  <Image src={great} alt={''} width={40} height={40} key={2} />,
];

export const SleepDiaryConfig: ConfigType = {
  tags: {
    sleepDiary: {
      render: 'SleepDiary',
      attributes: {
        name: {
          type: String,
          required: true,
        },
      },
    },
  },
};

const isPastOrPresentDate = (dateString: string): boolean => {
  // Parse the input date string
  const date = dayjs(dateString, 'MMMM/DD/YYYY');

  // Get the current date in the user's local time
  const currentDate = dayjs().tz(dayjs.tz.guess()).startOf('day');

  // Compare the input date with the current date
  return date.isSame(currentDate) || date.isBefore(currentDate);
};

interface SleepDiaryProps {
  name: string;
}

export const SleepDiary: React.FC<SleepDiaryProps> = ({
  name,
}: SleepDiaryProps) => {
  const [saving, setSaving] = useState<number>(0);
  const auth = useAuth();
  const sections = [`tools`];
  const { program, interactions } = useProgram(sections);
  const [queryState] = useQueryState(program);
  // const [queryEvents, { data: SDEvents }] = useEventsLazyQuery();
  const [createProgramEvent] = useCreateProgramEvent('Sleep diary edit');
  const [upsertState] = useUpsertState();
  const [SDInteraction, setSDInteraction] = useState<Interaction | null>(null);
  const [SDState, setSDState] = useState<any>({});
  const [entriesNumber, setEntiresNumber] = useState<number>(0);

  useEffect(() => {
    if (
      SDInteraction &&
      SDState &&
      SDState.payload &&
      Object.values(SDState.payload.entries).length === 0
    ) {
      const startDate = dayjs(SDState.payload.scheduledExercises[0][0]);
      const today = dayjs(formatDateString(new Date()));
      if (today > startDate) {
        upsertState({
          ...(SDState || {}),
          userId: auth.user?.id as number,
          programId: program?.id as number,
          interactionId: SDInteraction.id as number,
          payload: {
            startDate: formatDateString(new Date()),
            scheduledExercises: [generateFutureDates(new Date(), 5)],
            entries: {},
          },
          meta: {},
        });
      }
    }
  }, [SDState, SDInteraction]);

  const formReducer = (
    state: State,
    action: { type: string; field?: string; value?: string | null | State }
  ) => {
    switch (action.type) {
      case 'updateFieldValue': {
        if (!action.field) {
          return state;
        }
        const newState = {
          ...state,
          [action.field]: action.value,
        };
        if (
          SDInteraction &&
          SDState &&
          action.field !== 'date' &&
          isPastOrPresentDate(newState.date)
        ) {
          const entries = { ...SDState.payload.entries };
          entries[newState.date] = newState;
          setSaving(1);
          upsertState({
            ...(SDState || {}),
            userId: auth.user?.id as number,
            programId: program?.id as number,
            interactionId: SDInteraction.id as number,
            payload: {
              ...(SDState?.payload || {}),
              entries,
            },
          }).then(() => {
            setSaving(2);
          });
          createProgramEvent({
            orgId: program?.orgId as number,
            programId: program?.id as number,
            interactionId: SDInteraction?.id as number,
            payload: { action },
          });
        }
        return newState;
      }
      case 'setState': {
        return {
          ...(action.value as State),
        };
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer<React.Reducer<State, any>>(
    formReducer,
    initialState()
  );
  const { setComponentState } = useFormState('sleepDiary', name);

  const handlePreviousEntry = () => {
    if (
      state.date &&
      state.date !== 'Invalid Date' &&
      SDState &&
      SDState.payload
    ) {
      const prevEntry = SDState.payload.entries[state.date] && {
        ...SDState.payload.entries[state.date],
      };
      if (prevEntry) {
        setSaving(2);
        dispatch({
          type: 'setState',
          value: prevEntry,
        });
      } else {
        dispatch({
          type: 'setState',
          value: initialState(dayjs(state.date, 'MMMM/DD/YYYY').toDate()),
        });
      }
    }
  };

  useEffect(() => {
    handlePreviousEntry();
  }, [state.date, SDState]);

  const handleSDState = async () => {
    if (interactions) {
      const SD = interactions.find((inter) => inter.name === 'Sleep diary');
      if (SD) {
        const interactionState = queryState(SD);
        setSDInteraction(SD as Interaction);
        if (interactionState) {
          setSDState(interactionState);
        }
      }
    }
  };

  useEffect(() => {
    handleSDState();
  });

  const handleChange = () => {
    setComponentState(JSON.stringify(state));
  };

  useEffect(() => {
    handleChange();
  }, [state]);

  const handleDateSelect = (date: Date) => {
    dispatch({
      type: 'updateFieldValue',
      field: 'date',
      value: formatDateString(date),
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({
      type: 'updateFieldValue',
      field: event.target.name,
      value: event.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (SDInteraction && SDState) {
        const entries = { ...SDState.payload.entries };
        entries[state.date] = state;
        await upsertState({
          ...(SDState || {}),
          userId: auth.user?.id as number,
          programId: program?.id as number,
          interactionId: SDInteraction.id as number,
          payload: {
            ...(SDState?.payload || {}),
            entries,
          },
        });
      }
    } catch (submitError) {
      console.error(submitError);
      // handled by error
    }
  };

  useEffect(() => {
    if (SDState && SDState?.payload) {
      let num = 0;
      const SEIndex = (SDState?.payload.scheduledExercises.length || 0) - 1;
      SDState?.payload.scheduledExercises[SEIndex].map((scheduled: string) => {
        if (SDState.payload.entries[scheduled]) {
          num += 1;
        }
        return null;
      });
      setEntiresNumber(num);
    }
  }, [SDState]);

  return (
    <div className="w-full">
      <div className="mx-auto ">
        <div className=" pb-5 text-center">
          <div className="text-xl font-semibold md:text-lg">Sleep diary</div>
          {SDState?.payload?.entries &&
            SDState?.payload?.scheduledExercises[0] && (
              <div>
                {`${entriesNumber} of ${
                  SDState?.payload?.scheduledExercises[
                    (SDState?.payload.scheduledExercises.length || 0) - 1
                  ]?.length
                } entries completed for this week`}
              </div>
            )}
        </div>
        <div className="flex flex-col xl:flex-row">
          <div className="mx-auto pb-5">
            {SDState &&
              SDState.payload &&
              SDState.payload.scheduledExercises && (
                <ToolCalendar
                  selectedDay={dayjs(state.date, 'MMMM/DD/YYYY').toDate()}
                  onSelect={handleDateSelect}
                  scheduledExercises={SDState.payload.scheduledExercises}
                  SDEntries={SDState.payload.entries}
                />
              )}

            {/* <div>
              <Button onClick={handleSubmit}>Submit</Button>
            </div> */}
          </div>
          <div className="mx-auto py-4 md:w-4/5 md:rounded-3xl md:border md:border-stone-300 md:px-6">
            <form
              className="flex w-full  flex-col gap-4 md:px-3"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col justify-center text-center md:flex-row md:justify-between md:text-left">
                <div className="w-full text-2xl font-semibold">
                  {state.date &&
                    dayjs(state.date, 'MMMM/DD/YYYY').format('MMMM D, YYYY')}
                </div>
                <div className="flex w-full  justify-center md:justify-end">
                  {saving === 2 && (
                    <div className="flex items-center">
                      <IoCheckmark className="mr-1" />
                      <div>Saved</div>
                    </div>
                  )}
                </div>
              </div>
              <div className=" w-full">
                <SleepScheduleSlider
                  value={state.sleepSchedule}
                  onChange={(value) => {
                    dispatch({
                      type: 'updateFieldValue',
                      field: 'sleepSchedule',
                      value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col border-b border-dotted border-stone-500  pb-2 md:flex-row">
                <div className="mb-2 w-4/5">
                  <div className="text-lg font-semibold">
                    {'Falling asleep'}
                  </div>
                  <div>{'How long did it take to fall asleep?'}</div>
                </div>
                <div className="flex items-center justify-start md:w-1/5 md:justify-center">
                  <div className="mx-2 w-16">
                    <TextInput
                      id="fallAsleep"
                      type="number"
                      required={true}
                      name="fallAsleep"
                      value={state.fallAsleep}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>{'minutes'}</div>
                </div>
              </div>
              <div className="border-b border-dotted border-stone-500 pb-2">
                <div className="mb-2 block">
                  <div className="text-lg font-semibold">
                    {'Sleep interruptions'}
                  </div>
                  <div>
                    {
                      'Enter each time during the night that your sleep was interrupted'
                    }
                  </div>
                </div>
                <div className="border-y border-stone-300 py-1">
                  {state &&
                    state.sleepInterruptions &&
                    state.sleepInterruptions.map((interruption, index) => {
                      return (
                        <div
                          className="my-2 flex w-full items-center justify-between"
                          key={`SI-${index}`}
                        >
                          <div className="flex w-1/3 items-baseline">
                            <div className="mx-2">{index + 1}</div>
                            <div className="flex items-center">
                              <input
                                className="mx-2 w-16 rounded-xl border p-2"
                                placeholder="Duration"
                                type="number"
                                value={state.sleepInterruptions[index]}
                                name={`sleepInterruptions-${index}`}
                                onChange={(e) => {
                                  const newSIArray = [
                                    ...state.sleepInterruptions,
                                  ];
                                  newSIArray[index] = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  dispatch({
                                    type: 'updateFieldValue',
                                    field: 'sleepInterruptions',
                                    value: newSIArray,
                                  });
                                }}
                              />
                              <div>minutes</div>
                            </div>
                          </div>
                          <div className="mx-5 flex w-1/3 justify-end text-3xl">
                            <FiMinusCircle
                              className="mx-2"
                              onClick={() => {
                                const newSIArray = [
                                  ...state.sleepInterruptions,
                                ];
                                newSIArray.splice(index, 1);
                                dispatch({
                                  type: 'updateFieldValue',
                                  field: 'sleepInterruptions',
                                  value: newSIArray,
                                });
                              }}
                            />
                            <FiPlusCircle
                              className="mx-2"
                              onClick={() => {
                                dispatch({
                                  type: 'updateFieldValue',
                                  field: 'sleepInterruptions',
                                  value: [...state.sleepInterruptions, 20],
                                });
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {state &&
                    state.sleepInterruptions &&
                    state.sleepInterruptions.length === 0 && (
                      <div className="mx-5 flex w-1/3 text-3xl">
                        <FiPlusCircle
                          className="mx-2"
                          onClick={() => {
                            dispatch({
                              type: 'updateFieldValue',
                              field: 'sleepInterruptions',
                              value: [...state.sleepInterruptions, 20],
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
              <div className="border-b border-dotted border-stone-500">
                <div className="mb-2 block">
                  <div className="text-lg font-semibold">{'Sleep quality'}</div>
                </div>
                <div className="border-y py-1">
                  <fieldset
                    className="flex w-full flex-col gap-4"
                    name="sleepQuality"
                  >
                    <legend className="mb-4">
                      {
                        'How would you rate your overall sleep quality last night?'
                      }
                    </legend>
                    <div className="flex w-full justify-around">
                      {sleepQualityAIcons.map((icon, index) => (
                        <div
                          key={index}
                          className={`mx-1 flex cursor-pointer flex-col items-center ${
                            state.sleepQualityA === sleepQualityALabels[index]
                              ? 'font-semibold text-black'
                              : 'text-gray-500'
                          }`}
                          onClick={() => {
                            dispatch({
                              type: 'updateFieldValue',
                              field: 'sleepQualityA',
                              value: sleepQualityALabels[index],
                            });
                          }}
                        >
                          {icon}
                          <span className="mt-2">
                            {sleepQualityALabels[index]}
                          </span>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                  <fieldset className="flex w-full flex-col gap-4 ">
                    <legend className="mb-4 pt-5">
                      {'How does last night compare to other nights?'}
                    </legend>
                    <div className="flex w-full justify-around md:justify-start">
                      {sleepQualityBIcons.map((icon, index) => (
                        <div
                          key={index}
                          className={`mx-2 flex cursor-pointer flex-col items-center ${
                            state.sleepQualityB === sleepQualityBLabels[index]
                              ? 'font-semibold text-black'
                              : 'text-gray-500'
                          }`}
                          onClick={() => {
                            dispatch({
                              type: 'updateFieldValue',
                              field: 'sleepQualityB',
                              value: sleepQualityBLabels[index],
                            });
                          }}
                        >
                          {icon}
                          <span className="mt-2">
                            {sleepQualityBLabels[index]}
                          </span>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="border-b border-dotted border-stone-500">
                <div className="mb-2 block">
                  <div className="text-lg font-semibold">{'Sleep notes'}</div>
                  <div>
                    {'Note anything important or memorable about your sleep'}
                  </div>
                </div>
                <div className="">
                  <textarea
                    className="w-full rounded-lg border border-gray-300 md:m-5 md:h-36"
                    name="sleepNotes"
                    placeholder="Let your thoughts flow freely"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="">
                <div className="mb-2 block">
                  <div className="text-lg font-semibold">{'Daytime naps'}</div>
                  <div>{'Enter all naps for yesterday'}</div>
                </div>
                <div className="border-y border-stone-300 py-1">
                  {state &&
                    state.daytimeNaps &&
                    state.daytimeNaps.map((nap, index) => {
                      return (
                        <div
                          className="my-2 flex w-full items-center justify-between"
                          key={`SI-${index}`}
                        >
                          <div className="flex w-1/3 items-baseline">
                            <div className="mx-2">{index + 1}</div>
                            <div className="flex items-center">
                              <input
                                className="mx-2 w-16 rounded-xl border p-2"
                                placeholder="Duration"
                                type="number"
                                value={state.daytimeNaps[index]}
                                name={`daytimeNaps-${index}`}
                                onChange={(e) => {
                                  const newSIArray = [...state.daytimeNaps];
                                  newSIArray[index] = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  dispatch({
                                    type: 'updateFieldValue',
                                    field: 'daytimeNaps',
                                    value: newSIArray,
                                  });
                                }}
                              />
                              <div>minutes</div>
                            </div>
                          </div>
                          <div className="mx-5 flex w-1/3 justify-end text-3xl">
                            <FiMinusCircle
                              className="mx-2"
                              onClick={() => {
                                const newSIArray = [...state.daytimeNaps];
                                newSIArray.splice(index, 1);
                                dispatch({
                                  type: 'updateFieldValue',
                                  field: 'daytimeNaps',
                                  value: newSIArray,
                                });
                              }}
                            />
                            <FiPlusCircle
                              className="mx-2"
                              onClick={() => {
                                dispatch({
                                  type: 'updateFieldValue',
                                  field: 'daytimeNaps',
                                  value: [...state.daytimeNaps, 20],
                                });
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {state &&
                    state.daytimeNaps &&
                    state.daytimeNaps.length === 0 && (
                      <div className="mx-5 flex w-1/3 text-3xl">
                        <FiPlusCircle
                          className="mx-2"
                          onClick={() => {
                            dispatch({
                              type: 'updateFieldValue',
                              field: 'daytimeNaps',
                              value: [...state.daytimeNaps, 20],
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
