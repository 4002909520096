import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import Image from 'next/image';
import img from 'public/assets/images/pillow.png';
import React from 'react';

export const PillowConfig: ConfigType = {
  tags: {
    pillow: {
      render: 'Pillow',
      children: [],
    },
  },
};
interface PillowProps {
  children?: React.ReactNode[];
}

export const Pillow: React.FC<PillowProps> = ({ children }) => {
  const { isMobile } = useWindowSize();

  return (
    <div className="my-0 flex flex-col items-start  md:flex-row md:flex-nowrap ">
      <div
        className={classNames(
          'mt-3 mb-5 md:mr-1',
          isMobile ? 'flex justify-center w-full' : ''
        )}
        style={{ color: '#1C33AE' }}
      >
        <Image src={img} alt="pillow" width={76} height={60} className="mr-2" />
      </div>
      <div className=" text-center text-xl text-black md:float-left md:w-3/4 md:text-left md:text-2xl">
        {children}
      </div>
    </div>
  );
};
