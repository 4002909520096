import type { ConfigType } from '@markdoc/markdoc';
import { BsClipboardCheck } from 'react-icons/bs';
import { FaRegHeart } from 'react-icons/fa';
import { FaStaffSnake, FaUserDoctor } from 'react-icons/fa6';
import { IoIosPhonePortrait } from 'react-icons/io';
import { MdLockOutline } from 'react-icons/md';

import bottomImage from '../../../public/assets/images/contentInChair.jpg';
import topImage from '../../../public/assets/images/sittingWithPhone.png';
import { FadeInImage } from '../../components/image/FadeInImage';

export const OverviewConfig: ConfigType = {
  tags: {
    overview: {
      render: 'Overview',
      children: [],
      attributes: {
        className: {
          type: String,
          required: false,
        },
      },
    },
  },
};

interface OverviewProps {
  className?: string;
}

export const Overview: React.FC<OverviewProps> = () => {
  return (
    <div className="text-center text-black lg:px-24">
      <div className="justify-center text-left sm:flex md:px-10">
        <div className="max-w-xl px-14 pt-10 sm:pt-24 md:px-14">
          <FadeInImage
            className="animate-fade rounded-3xl"
            src={topImage}
            alt={''}
          />
        </div>
        <div className="max-w-xl pr-2 md:pt-16 xl:pt-24">
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <IoIosPhonePortrait />
            </div>
            <div className="">
              <div className="font-bold">Easy Access to Expert Care</div>
              <div>
                Receive digital treatments designed by clinicians, without any
                complicated tech or jargon.
              </div>
            </div>
          </div>
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <FaRegHeart />
            </div>
            <div>
              <div className="font-bold">Engaging, Supportive Experiences</div>
              <div>
                Enjoy a user-friendly approach to care, developed with years of
                expertise in digital medicine to keep you engaged and motivated.
              </div>
            </div>
          </div>
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <BsClipboardCheck />
            </div>
            <div>
              <div className="font-bold">Clinical Validation</div>
              <div>
                We include analytics tools to allow your doctor to track your
                progression and ensure these treatments are meeting your needs.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center pr-2 text-left sm:flex md:px-10">
        <div className="max-w-xl md:pt-24 xl:pt-40">
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <MdLockOutline />
            </div>
            <div>
              <div className="font-bold">Private + Secure</div>
              <div>
                Our platform is built with data security at the core. We have
                rigorous guidelines to keep your data and information protected.
              </div>
            </div>
          </div>
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <FaStaffSnake />
            </div>
            <div>
              <div className="font-bold">Regulatory Clearance</div>
              <div>
                Our digital treatments are designed to meet all regulatory
                standards, ensuring safe and reliable care without the hassle.
              </div>
            </div>
          </div>
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <FaUserDoctor />
            </div>
            <div>
              <div className="font-bold">
                Seamless Coordination with Your Care Team
              </div>
              <div>
                Your care is connected with your existing healthcare providers,
                ensuring they stay informed and involved throughout your
                treatment journey.
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-xl px-14 py-10 sm:pt-36 md:px-14">
          <FadeInImage className="rounded-3xl" src={bottomImage} alt={''} />
        </div>
      </div>
    </div>
  );
};
