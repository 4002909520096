import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDateString = (date: Date): string => {
  const test = dayjs(date).format('MMMM/DD/YYYY');
  return test;
};

export const generateFutureDates = (
  day1: Date,
  numberOfDays: number
): string[] => {
  const dates: string[] = [];
  for (let i = 0; i < numberOfDays; i += 1) {
    const futureDate = dayjs(day1)
      .add(i, 'day')
      .set('hour', 12)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);
    dates.push(formatDateString(futureDate.toDate()));
  }
  return dates;
};
