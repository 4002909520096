import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';

export const ImageTagConfig: ConfigType = {
  tags: {
    imgTag: {
      render: 'ImageTag',
      attributes: {
        className: {
          type: String,
          required: false,
        },
        src: {
          type: String,
          required: true,
        },
      },
    },
  },
};

interface ImgProps {
  className?: string;
  src: string;
}

export const ImageTag: React.FC<ImgProps> = ({ className, src }) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={classNames(className)}>
      {isMobile ? (
        <Image className=" " width={160} height={160} src={src} alt="" />
      ) : (
        <Image className=" " width={271} height={264} src={src} alt="" />
      )}
    </div>
  );
};
