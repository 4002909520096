import { useAuth } from '../../modules/authentication/useAuth';
import type {
  AllStatesQuery,
  Program,
  State,
  StatesQuery,
} from '../../modules/graphql/generated-types';
import {
  AllStatesDocument,
  useAllStatesQuery,
  useCreateStateMutation,
  useUpdateStateMutation,
} from '../../modules/graphql/generated-types';

type QueryState = [
  (interaction: { id: number }) => Partial<State> | null,
  { loading: boolean; error: any }
];

export const useQueryState = (program?: Partial<Program>): QueryState => {
  const auth = useAuth();
  const { loading, error, client } = useAllStatesQuery({
    variables: {
      userId: auth.user?.id as number,
    },
    skip: auth.loading || !program,
  });

  if (!program) {
    return [() => null, { loading: false, error: null }];
  }

  const queryState = (interaction: { id: number }) => {
    const mostRecentData = client.readQuery<AllStatesQuery>({
      query: AllStatesDocument,
      variables: {
        userId: auth.user?.id as number,
      },
    });
    const state =
      interaction &&
      mostRecentData?.states?.nodes?.find(
        (s) =>
          s?.interactionId === interaction.id && s?.programId === program.id
      );
    if (state) {
      return state;
    }
    return null;
  };
  return [queryState, { loading, error }];
};

type UpsertState = [
  (state: Partial<State>) => Promise<any>,
  { loading: boolean; error: any }
];

/**
 * This hook is used to update the state of an interaction. It will create a new state if one does not exist.
 *
 * @param state new or updated state
 */
export const useUpsertState = (): UpsertState => {
  const [createState, { loading, error }] = useCreateStateMutation();
  const [updateState, { loading: updateLoading, error: updateError }] =
    useUpdateStateMutation();

  return [
    (state: Partial<State>) => {
      if (!state.id) {
        // create state
        try {
          return createState({
            variables: {
              userId: state.userId as number,
              programId: state.programId as number,
              interactionId: state.interactionId as number,
              state: state.state,
              payload: state.payload,
              meta: state.meta,
            },
            update: (apolloCache, mutationResults) => {
              const staleStates = apolloCache.readQuery<StatesQuery>({
                query: AllStatesDocument,
                variables: { userId: state.userId },
              });
              const newStates = [
                ...(staleStates?.states?.nodes || []),
                mutationResults.data?.createState?.state,
              ];
              apolloCache.writeQuery({
                query: AllStatesDocument,
                variables: { userId: state.userId },
                data: {
                  states: { __typename: 'StatesConnection', nodes: newStates },
                },
              });
            },
          });
        } catch (e: any) {
          // if state already exists, update it
          if (
            e.message.includes('duplicate key value violates unique constraint')
          ) {
            throw new Error('State already exists');
            // TODO: query for the state and update it below
          }
        }
      }

      // update state
      return updateState({
        variables: {
          id: state.id as number,
          state: state.state,
          payload: state.payload,
          meta: state.meta,
        },
      });
    },
    {
      loading: loading || updateLoading,
      error: error || updateError,
    },
  ];
};
