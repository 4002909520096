import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import React from 'react';

const config: ConfigType = {
  tags: {
    columnDisplay: {
      render: 'ColumnDisplay',
      children: [],
      attributes: {
        className: {
          type: String,
          required: false,
        },
        columnClassName: {
          type: String,
          required: false,
        },
        center: {
          type: Boolean,
          required: false,
        },
      },
    },
  },
};

interface ColumnDisplayProps {
  children?: React.ReactNode[];
  className?: string;
  columnClassName?: string;
  center?: boolean;
}

const ColumnDisplay: React.FC<ColumnDisplayProps> = ({
  children,
  columnClassName,
  className,
  center = false,
}) => {
  const columns: React.ReactNode[][] = [];
  // let columnCount = 1;
  let currentColumn: React.ReactNode[] = [];

  if (children && children.length) {
    children.map((child: any, index) => {
      if (child.type === 'hr') {
        // columnCount = columnCount + 1;
        columns.push([...currentColumn]);
        currentColumn = [];
      } else {
        currentColumn.push(child);
        if (index === children.length - 1) {
          columns.push([...currentColumn]);
        }
      }
      return null;
    });
  }

  return (
    <div
      className={classNames(
        'h-full w-full flex flex-col md:flex-row md:flex-nowrap ',
        className
      )}
    >
      {columns.map((column, index) => {
        return (
          <div
            key={index}
            className={classNames(
              'h-full ',
              columnClassName || 'my-auto md:w-1/2',
              center && 'flex justify-center'
            )}
          >
            <div className="">{column}</div>
          </div>
        );
      })}
    </div>
  );
};

export { ColumnDisplay, config as columnDisplayConfig };
