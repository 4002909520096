import { useSessionViewerState } from '@drugfreesleep/components/sessionViewerProvider/useSessionViewerState';
import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import type { ConfigType } from '@markdoc/markdoc';
import { Button } from 'flowbite-react';
import router from 'next/router';
import React, { useEffect, useReducer } from 'react';
import { IoArrowForward } from 'react-icons/io5';

import { useAuth } from '../authentication/useAuth';
import { useSetEmailMutation } from '../graphql/generated-types';
import { useFormState } from './useFormState';

type State = {
  email: string;
  required?: boolean;
};

const formReducer = (
  state: State,
  action: { type: string; field?: string; value?: string | null }
) => {
  if (!action.field) return state;
  switch (action.type) {
    case 'updateFieldValue':
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export const VerifyEmailConfig: ConfigType = {
  tags: {
    verifyEmail: {
      render: 'VerifyEmail',
      attributes: {
        name: {
          type: String,
          required: true,
        },
        required: {
          type: Boolean,
          required: false,
        },
      },
    },
  },
};

export interface EmailVerificationProps {
  name: string;
  required?: boolean;
}

export const VerifyEmail: React.FC<EmailVerificationProps> = ({
  name,
  required,
}: EmailVerificationProps) => {
  const auth = useAuth();
  const programSlug = useProgramSlug();
  const [setEmail, { loading, data }] = useSetEmailMutation();

  const {
    componentState,
    setComponentState,
    index: pathIndex,
  } = useFormState('verifyEmail', name);
  const [state, dispatch] = useReducer<React.Reducer<State, any>>(formReducer, {
    email: componentState.value || '',
    required,
  });

  const { setDisableNext, setEnableNext } = useSessionViewerState(
    name,
    pathIndex
  );

  useEffect(() => {
    if (required) {
      setDisableNext();
    }
  }, []);

  if (auth.emailVerified) {
    return <></>;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'updateFieldValue',
      field: event.target.name,
      value: event.target.value,
    });
    setComponentState(event.target.value);
  };

  const handleSetEmail = async (email: string) => {
    await setEmail({
      variables: {
        email,
        domain: window.location.origin,
      },
    });
    setEnableNext();
  };

  const handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (state.email) {
      handleSetEmail(state.email);
    }
  };

  return (
    <div className="flex justify-center">
      {!data ? (
        <form
          className="flex max-w-[30rem] flex-col md:mx-16 "
          onSubmit={handleEmailSubmit}
        >
          <div>
            <div className="mb-3 text-center text-3xl font-semibold text-black">
              We noticed you haven&apos;t provided an email yet.
            </div>
            <div className="flex flex-col justify-center">
              <div className="mb-3 flex justify-center">
                <div className="text-xs text-black md:text-sm">
                  Using your email helps us offer personalized reminders and
                  support as you progress through the program. It also ensures
                  that you can recover your account if needed.
                </div>
              </div>
              <div className="flex justify-center">
                <input
                  className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700"
                  placeholder="Email"
                  id="email"
                  type="email"
                  name="email"
                  style={{ maxWidth: '600px', minWidth: '350px' }}
                  value={state.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex w-full justify-center">
            <Button
              style={{
                width: '170px',
                borderRadius: '2rem',
                backgroundColor: 'rgb(30 64 175)',
              }}
              className="bg-blue-800"
              type="submit"
              disabled={!state.email || loading}
            >
              Submit
              <IoArrowForward />
            </Button>
          </div>
          {!required && (
            <div className="mt-2 text-center align-baseline text-black">
              <div
                className="cursor-pointer underline"
                onClick={() =>
                  router.push(
                    programSlug !== undefined
                      ? `/p/${programSlug}/today`
                      : `/today`
                  )
                }
              >
                Skip for now
              </div>
            </div>
          )}
        </form>
      ) : (
        <div>
          <div className="text-center text-lg">
            <div className="mb-0 text-2xl font-semibold">Thank You!</div>
            <div className="mb-0 text-lg ">
              Please check your inbox for our verification email!
            </div>
            <div className=" text-sm">
              <div>{state.email}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
