import React, { createContext, useReducer } from 'react';

// State structure for the context
export type SessionViewerState = {
  [index: number]: {
    [name: number]: boolean;
  };
};

// Actions for the reducer
type SessionViewerAction = {
  type: 'DISABLE_NEXT' | 'ENABLE_NEXT';
  payload: {
    name: string;
    index: number;
    value?: string | number | boolean | string[];
  };
};

// Define the context with an empty state and a placeholder dispatch function
export const ViewerContext = createContext<{
  state: SessionViewerState;
  dispatch: React.Dispatch<SessionViewerAction>;
}>({
  state: {},
  dispatch: () => null,
});

// The reducer function to update the state
function formReducer(
  state: SessionViewerState,
  action: SessionViewerAction
): SessionViewerState {
  switch (action.type) {
    case 'DISABLE_NEXT':
      return {
        ...state,
        [action.payload.index]: {
          ...state[action.payload.index],
          [action.payload.name]: false,
        },
      };
    case 'ENABLE_NEXT':
      return {
        ...state,
        [action.payload.index]: {
          ...state[action.payload.index],
          [action.payload.name]: true,
        },
      };
    default:
      return state;
  }
}

interface IFormProviderProps {}

// Context Provider component
export const ViewerProvider = ({
  children,
}: React.PropsWithChildren<IFormProviderProps>) => {
  const [state, dispatch] = useReducer(formReducer, {});

  return (
    <ViewerContext.Provider value={{ state, dispatch }}>
      {children}
    </ViewerContext.Provider>
  );
};
