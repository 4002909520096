import { useContext } from 'react';

import { FormContext } from './FormContext';

// Custom hook to access and update the form state
export function useFormState(
  componentType: string,
  name: string,
  defaultValue?: any
) {
  const { state, dispatch, index } = useContext(FormContext);

  // Get the component state
  const componentState = (state && state[name]) || {
    type: componentType,
    name,
    value: defaultValue,
  };

  // Set the component state
  const setComponentState = (value: string | number | boolean | string[]) => {
    dispatch({
      type: 'SET_VALUE',
      payload: { name, value },
    });
  };

  return { state, componentState, setComponentState, index };
}
export function useFormStateResult() {
  const { state } = useContext(FormContext);

  return state;
}
