import type { ConfigType } from '@markdoc/markdoc';
import React from 'react';

import { LoginMD } from '../authentication/LoginMD';

const config: ConfigType = {
  tags: {
    register: {
      render: 'Register',
      attributes: {
        name: {
          type: String,
          required: true,
        },
      },
    },
  },
};

interface RegisterProps {
  name: string;
}

const Register: React.FC<RegisterProps> = () => {
  return (
    <div className="">
      <LoginMD showRegister={true} routeHome={true} />
    </div>
  );
};

export { Register, config as RegisterConfig };
