import { VerticalFeatureRow } from '@drugfreesleep/components/feature/VerticalFeatureRow';
import { Section } from '@drugfreesleep/components/layout/Section';
import type { ConfigType } from '@markdoc/markdoc';
import cx from 'classnames';
import * as React from 'react';

import styles from './FeatureTag.module.scss';

const config: ConfigType = {
  tags: {
    Feature: {
      render: 'Feature',
      // children: [],
      attributes: {
        class: {
          type: String,
          errorLevel: 'critical',
        },
        title: {
          type: String,
          errorLevel: 'critical',
        },
        subtitle: {
          type: String,
          default: ' ',
          required: false,
          errorLevel: 'critical',
        },
        imgSrc: {
          type: String,
          required: false,
          errorLevel: 'critical',
        },
        align: {
          type: String,
          errorLevel: 'error',
          default: 'left',
          matches: ['left', 'right', 'center'],
          required: false,
        },
      },
      selfClosing: false,
    },
  },
};

interface IProps {
  title: string;
  subtitle: string;
  className: string; // Actually it is an object of the form { <className>: boolean }
  imgSrc: string;
  align: 'left' | 'right' | 'center';
  children: React.ReactNode;
}

const FeatureTag = ({
  title,
  subtitle,
  imgSrc,
  align = 'left',
  children,
  className,
}: IProps) => {
  if (align === 'center') {
    return (
      <div className={cx(className)}>
        <Section subtitle={subtitle} title={title}>
          <div className="mx-auto w-full text-lg md:w-1/2">
            {imgSrc && (
              <div className="mb-12">
                <img src={imgSrc} alt="headshot" className="mx-auto max-w-xs" />
              </div>
            )}
            {children}
          </div>
        </Section>
      </div>
    );
  }
  return (
    <div className={cx(className)}>
      <Section>
        <VerticalFeatureRow
          title={title}
          subtitle={subtitle}
          reverse={align === 'left'}
          image={{
            src: imgSrc,
            alt: 'First feature alt text',
          }}
        >
          <div className={styles.feature}>{children}</div>
        </VerticalFeatureRow>
      </Section>
    </div>
  );
};

FeatureTag.config = config;

export { FeatureTag };
