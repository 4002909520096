import { Logo } from '@drugfreesleep/template/Logo';
import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import React from 'react';

const config: ConfigType = {
  tags: {
    bgImg: {
      render: 'BackgroundImage',
      children: [],
      attributes: {
        src: {
          type: String,
          required: true,
        },
        logo: {
          type: Boolean,
          required: false,
        },
        rounded: {
          type: Boolean,
          required: false,
        },
        className: {
          type: String,
          required: false,
        },
        fullscreen: {
          type: Boolean,
          required: false,
          default: true,
        },
        childClassName: {
          type: String,
          required: false,
        },
        hideOnMobile: {
          type: Boolean,
          required: false,
        },
      },
    },
  },
};

interface BackgroundImageProps {
  src: string;
  className?: string;
  childClassName?: string;
  logo?: boolean;
  rounded?: boolean;
  children?: React.ReactNode[];
  fullscreen?: Boolean;
  hideOnMobile?: Boolean;
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  children,
  src,
  className,
  childClassName,
  logo = false,
  rounded = false,
  fullscreen = true,
  hideOnMobile = false,
}) => {
  const { isMobile } = useWindowSize();

  const style: React.CSSProperties = {
    backgroundImage: `url(${src})`,
    backgroundPosition: 'top',
  };
  if (rounded) {
    if (isMobile) {
      style.borderBottomLeftRadius = '6rem';
    } else {
      style.borderTopRightRadius = '6rem';
    }
  }
  if (!fullscreen && isMobile) {
    style.height = '50vh';
  }

  if (hideOnMobile && isMobile) {
    return <div></div>;
  }
  return (
    <div
      style={style}
      className={classNames(
        `px-5 w-full bg-cover  md:rounded-b-none bg-center flex justify-center`,
        className,
        !fullscreen && isMobile ? '' : 'h-screen'
      )}
    >
      {logo ? <Logo /> : null}
      <div className={classNames('', childClassName)}>{children}</div>
    </div>
  );
};

export { BackgroundImage, config as BackgroundImageConfig };
