import Image from 'next/image';
import img from 'public/assets/images/safari-pinned-tab.svg';
import darkImg from 'public/assets/images/safari-pinned-tab.white.svg';

import { AppConfig } from '../config/AppConfig';

type ILogoProps = {
  xl?: boolean;
  dark?: boolean;
};

const Logo = (props: ILogoProps) => {
  const size = props.xl ? '44' : '32';
  const fontStyle = props.xl
    ? 'font-semibold text-3xl'
    : 'font-semibold text-xl';

  const logoColor = props.dark ? 'text-white' : 'text-primary-500';
  const textColor = props.dark ? 'text-white' : 'text-gray-800';
  return (
    <div className={`flex items-center ${textColor} ${fontStyle}`}>
      <Image
        src={props.dark ? darkImg : img}
        alt=""
        width={size}
        height={size}
        className={`mr-1 stroke-current ${logoColor}`}
      />

      {AppConfig.site_name}
    </div>
  );
};

export { Logo };
