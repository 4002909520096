import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import classNames from 'classnames';
import React, { useState } from 'react';

import { MobileOTP } from './MobileOTP';
import { UsernameForm } from './UsernameForm';

interface IProps {
  hideSide?: boolean;
  showRegister?: boolean;
  routeHome?: boolean;
  isModal?: boolean;
}

export const LoginMD = ({
  showRegister = false,
  routeHome = false,
  isModal = false,
}: IProps) => {
  const [showMobileOTP, setShowMobileOTP] = useState(true);
  const { isMobile } = useWindowSize();

  return (
    <div
      className={`flex w-full flex-col md:flex-row md:flex-nowrap ${
        isModal && 'md:h-[40rem]'
      }`}
    >
      <div
        className={classNames(
          'mx-auto w-3/4 md:w-1/2 md:my-auto',
          'flex flex-col justify-center ',
          isMobile && 'my-24',
          {
            'min-h-screen': !isModal && !isMobile,
          }
        )}
      >
        {showMobileOTP ? (
          <MobileOTP
            setShowMobileOTP={setShowMobileOTP}
            showRegister={showRegister}
            routeHome={routeHome}
          />
        ) : (
          <UsernameForm
            showRegister={showRegister}
            setShowMobileOTP={setShowMobileOTP}
            routeHome={routeHome}
          />
        )}
      </div>
    </div>
  );
};
