import type { ConfigType } from '@markdoc/markdoc';
import React from 'react';

const config: ConfigType = {
  tags: {
    imageAndText: {
      render: 'ImageAndText',
      children: [],
      attributes: {
        src: {
          type: String,
          required: true,
        },
        imgSide: {
          type: String,
          required: false,
        },
      },
    },
  },
};

interface ImageAndTextProps {
  src: string;
  imgSide: string;
  children?: React.ReactNode[];
}

const ImageAndText: React.FC<ImageAndTextProps> = ({
  src,
  imgSide = 'right',
  children,
}) => {
  const flexDirection = imgSide === 'left' ? 'flex-row' : 'flex-row-reverse';
  let childrenWithNoBorder;
  // Apply style to remove borders from all children
  if (children) {
    childrenWithNoBorder = children.map((child) =>
      React.isValidElement(child)
        ? React.cloneElement(child as React.ReactElement<any>, {
            style: { border: 'none' },
          })
        : child
    );
  }

  return (
    <div className={`flex ${flexDirection} items-center text-left`}>
      <div style={{ flex: '1' }}>
        <img src={src} alt="" style={{ width: '100px' }} />
      </div>
      <div style={{ flex: '2', border: 'none' }}>{childrenWithNoBorder}</div>
    </div>
  );
};

export { ImageAndText, config as imageAndTextConfig };
