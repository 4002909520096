import type { ConfigType } from '@markdoc/markdoc';
import { useEffect, useState } from 'react';

import { useFormState } from './useFormState';

const config: ConfigType = {
  tags: {
    range: {
      render: 'Range',
      attributes: {
        name: { type: String, required: true },
        min: { type: Number, default: 0 },
        max: { type: Number, default: 10 },
        defaultValue: { type: Number, default: 0 },
        minLabel: { type: String, default: '' },
        midLabel: { type: String, default: '' },
        maxLabel: { type: String, default: '' },
      },
    },
  },
};

interface RangeProps {
  name: string;
  min?: number;
  max?: number;
  defaultValue?: number; // Renamed from 'value' to avoid confusion with the value state
  minLabel?: string;
  midLabel?: string;
  maxLabel?: string;
}

const Range: React.FC<RangeProps> = ({
  name,
  min = 0,
  max = 100,
  defaultValue = 50,
  minLabel = '',
  midLabel = '',
  maxLabel = '',
}) => {
  const { componentState, setComponentState } = useFormState('range', name);
  const value = componentState.value ?? defaultValue;
  const [labelPosition, setLabelPosition] = useState('0%');

  // Calculate position of the hover label
  useEffect(() => {
    const newPosition = ((value - min) / (max - min)) * 100;
    setLabelPosition(`${newPosition}%`);
  }, [value, min, max]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setComponentState(newValue);
  };

  // Generate range numbers
  const rangeNumbers = Array.from({ length: max - min + 1 }, (_, i) => min + i);
  // Calculate the width for each label based on the range length
  const labelWidthPercentage = 100 / (max - min);

  return (
    <div className="flex flex-col">
      {/* Range labels (min, mid, max) */}
      <div className="mb-2 flex items-end justify-between text-xs font-medium text-gray-700 dark:text-white">
        <span style={{ maxWidth: '20%', whiteSpace: 'normal' }}>
          {minLabel}
        </span>
        <span style={{ maxWidth: '20%', whiteSpace: 'normal' }}>
          {midLabel}
        </span>
        <span style={{ maxWidth: '20%', whiteSpace: 'normal' }}>
          {maxLabel}
        </span>
      </div>

      {/* Number labels */}
      <div className="mb-4 flex">
        {rangeNumbers.map((num) => (
          <span
            key={num}
            className="text-center text-xs"
            style={{
              width: `${labelWidthPercentage}%`,
              // The first and last label should align with the extremes of the range input
              textAlign: 'center',
            }}
          >
            {num}
          </span>
        ))}
      </div>

      {/* Range input with dynamic label */}
      <div className="relative">
        <input
          id={name}
          type="range"
          min={min}
          max={max}
          value={value}
          className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 px-1 dark:bg-gray-700"
          onChange={handleChange}
        />
        <div
          className="absolute bg-inherit px-2 py-1 text-xs font-medium text-gray-700"
          style={{
            left: `calc(${labelPosition} - ${
              0.25 + parseInt(labelPosition, 10) / 100
            }rem)`,
            top: '-1.5rem',
            textAlign: 'center',
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

export { Range, config as rangeConfig };
