import React, { createContext, useEffect, useReducer } from 'react';

export type FormStateValue = string | number | boolean | string[] | any;
// State structure for the context
type FormState = {
  [name: string]: {
    type: string;
    name: string;
    value: FormStateValue;
  };
};

// Actions for the reducer
type FormAction = {
  type: 'SET_VALUE';
  payload: { name: string; value: string | number | boolean | string[] };
};

// Define the context with an empty state and a placeholder dispatch function
const FormContext = createContext<{
  state: FormState;
  dispatch: React.Dispatch<FormAction>;
  index: number;
}>({
  state: {},
  dispatch: () => null,
  index: 0,
});

// The reducer function to update the state
function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case 'SET_VALUE':
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          value: action.payload.value,
        },
      };
    default:
      return state;
  }
}

interface IFormProviderProps {
  initialState: FormState;
  onStateChange: (state: any) => void;
  index: number;
}

// Context Provider component
const FormProvider = ({
  children,
  initialState,
  onStateChange,
  index,
}: React.PropsWithChildren<IFormProviderProps>) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  useEffect(() => {
    if (onStateChange) {
      onStateChange(state);
    }
  }, [state]);

  return (
    <FormContext.Provider value={{ state, dispatch, index }}>
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };
