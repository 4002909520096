import className from 'classnames';
import type { ReactNode } from 'react';

type IVerticalFeatureRowProps = {
  title: string;
  subtitle?: string;
  children: ReactNode;
  image: {
    src: string;
    alt: string;
  };
  reverse?: boolean;
  action?: ReactNode;
};

const VerticalFeatureRow = (props: IVerticalFeatureRowProps) => {
  const verticalFeatureClass = className(
    'vertical-feature',
    'first:mt-0',
    'mt-16',
    'flex',
    'flex-wrap',
    'items-center',
    {
      'flex-row-reverse': props.reverse,
    }
  );
  const subtitle = props.subtitle || 'Awesome feature';

  return (
    <div className={verticalFeatureClass}>
      <div className="w-full md:w-1/2">
        <div className="text-sm font-bold text-primary-500">{subtitle}</div>
        <h3 className="mt-1 text-3xl font-semibold text-gray-900">
          {props.title}
        </h3>
        <div className="mt-3 text-lg leading-7">{props.children}</div>
        {props.action && <div className="mt-4">{props.action}</div>}
      </div>

      {props.image && (
        <div className="w-full p-10 sm:p-20 md:w-1/2 md:p-10 xl:p-20">
          <img src={props.image.src} alt={props.image.alt} />
        </div>
      )}

      <style jsx>
        {`
          .vertical-feature :global(p) {
            @apply mt-2;
          }

          .vertical-feature :global(ul) {
            @apply mt-2;
          }
        `}
      </style>
    </div>
  );
};

export { VerticalFeatureRow };
