import { useContext } from 'react';

import { ViewerContext } from './SessionViewerContext';

// Custom hook to access and update the form state
export function useSessionViewerState(name: string, index: number) {
  const { state, dispatch } = useContext(ViewerContext);

  // Get the component state
  const pageState = (state && state[index]) || {
    [name]: true,
  };

  const setDisableNext = () => {
    dispatch({
      type: 'DISABLE_NEXT',
      payload: { name, index },
    });
  };

  const setEnableNext = () => {
    dispatch({
      type: 'ENABLE_NEXT',
      payload: { name, index },
    });
  };

  return { state, pageState, setDisableNext, setEnableNext };
}
export function useSessionViewerStateResult() {
  const { state } = useContext(ViewerContext);

  return state;
}
