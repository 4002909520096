import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import { Button } from 'flowbite-react';
import Link from 'next/link';
import React from 'react';
import { IoArrowForwardOutline } from 'react-icons/io5';

export const FootConfig: ConfigType = {
  tags: {
    foot: {
      render: 'Foot',
      attributes: {
        className: {
          type: String,
          required: false,
        },
        title: {
          type: String,
          required: true,
        },
        desc: {
          type: String,
          required: true,
        },
        button: {
          type: String,
          required: false,
        },
        link: {
          type: String,
          required: false,
        },
      },
    },
  },
};

interface FootProps {
  className?: string;
  desc: string;
  title?: string;
  button?: string;
  link?: string;
}

export const Foot: React.FC<FootProps> = ({
  className,
  title = '',
  desc,
  button,
  link,
}) => {
  const pSlug = useProgramSlug();
  const { isMobile } = useWindowSize();

  // Split the title by ". " to create separate lines
  const titleLines = title.split('.').filter(Boolean);

  const buttonLink = link || `/p/${pSlug}/course`;

  return (
    <div
      className={classNames(
        'bg-blue-50 px-10 md:px-32 pt-20 pb-20 text-center',
        className
      )}
      style={{ borderTopLeftRadius: '2rem', borderTopRightRadius: '7rem' }}
    >
      <div className="flex flex-col items-center justify-center">
        <div className="text-4xl font-semibold text-blue-800 md:text-5xl">
          {isMobile ? (
            <div style={{ lineHeight: '2.5rem' }}>
              {titleLines.map((line, index) => (
                <div key={index}>
                  {line}.
                  <br />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ lineHeight: '3rem' }}>{title}</div>
          )}
        </div>
        <div className="py-10 text-xl font-semibold text-blue-800 md:text-3xl">
          {desc}
        </div>
        <div className="pb-20">
          <Link href={pSlug ? buttonLink : `/get-started`}>
            <Button
              className="md:py-2"
              style={{
                width: '250px',
                borderRadius: '5rem',
                backgroundColor: 'rgb(30 64 175)',
              }}
            >
              <div className="flex items-center rounded-full">
                <div className="mr-1 text-xl font-bold">
                  {button || `Start the course`}
                </div>
                <div>
                  <IoArrowForwardOutline />
                </div>
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
