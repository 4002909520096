import 'rc-slider/assets/index.css';

import dayjs from 'dayjs';
import Slider from 'rc-slider';
import React, { useEffect, useState } from 'react';

const marks = {
  0: '12pm',
  6: '6pm',
  12: '12am',
  18: '6am',
  24: '12pm',
};

interface IProps {
  value: number[];
  onChange: (t: any) => void;
}
const formatTime = (value: any) => {
  return dayjs()
    .startOf('day')
    .add(value + 12, 'hours')
    .format('HH:mm');
};

const parseTime = (time: any) => {
  const momentTime = dayjs(time, 'HH:mm');
  const test = momentTime.hour() - 12 + momentTime.minute() / 60;
  return test;
};

// const parseNextDayTime = (time: any) => {
//   const momentTime = moment(time, 'HH:mm');
//   const test = momentTime.hours() <= 12 ? momentTime.hours() : 12;
//   const test2 = test + momentTime.minutes() / 60;
//   console.log(
//     'time: ',
//     time,
//     'momentTime: ',
//     momentTime,
//     momentTime.hours(),
//     'test: ',
//     test,
//     'test2',
//     test2
//   );
//   return test2;
// };
export const SleepScheduleSlider = ({ value, onChange }: IProps) => {
  const [times, setTimes] = useState(value.map(formatTime));
  useEffect(() => {
    // console.log(value);
    setTimes(value.map(formatTime));
  }, [value]);

  const handleTimeChange = (index: number, newTime: any) => {
    const newValues = [...value];
    newValues[index] = parseTime(newTime);
    onChange(newValues);
  };
  const handleNextDayTimeChange = (index: number, newTime: any) => {
    const newValues = [...value];
    newValues[index] = parseTime(newTime);
    onChange(newValues);
  };

  return (
    <div className="rounded-3xl bg-blue-50 px-3 pt-5 pb-10 md:m-3 md:px-10">
      <div className="mb-5 text-center md:mb-10 md:text-left">
        <div className="text-xl font-semibold text-gray-900 md:text-2xl md:font-bold">
          Sleep schedule
        </div>
        <p className="text-gray-900 md:mb-6">Last night to this morning</p>
      </div>
      <div className="mb-8 flex w-full flex-col items-center justify-between md:flex-row">
        <div className="flex w-full justify-around">
          <div className="py-2">
            <p>Bedtime</p>
            <input
              type="time"
              value={times[0]}
              onChange={(e) => handleTimeChange(0, e.target.value)}
            />
          </div>
          <div className="py-2">
            <p>Lights out</p>
            <input
              type="time"
              value={times[1]}
              onChange={(e) => handleTimeChange(1, e.target.value)}
            />
          </div>
        </div>
        <div className="flex w-full justify-around">
          <div>
            <p>Wake up</p>
            <input
              type="time"
              value={times[2]}
              onChange={(e) => handleNextDayTimeChange(2, e.target.value)}
            />
          </div>
          <div>
            <p>Out of bed</p>
            <input
              type="time"
              value={times[3]}
              onChange={(e) => handleNextDayTimeChange(3, e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="px-2">
        <Slider
          range
          min={0}
          max={24}
          marks={marks}
          step={0.5}
          value={value}
          onChange={onChange}
          defaultValue={[9, 10, 18, 19]} // Example initial values
          handleStyle={[
            { backgroundColor: 'white', borderColor: '#0000FF' },
            { backgroundColor: 'white', borderColor: '#0000FF' },
            { backgroundColor: 'white', borderColor: '#0000FF' },
            { backgroundColor: 'white', borderColor: '#0000FF' },
          ]}
        />
      </div>
    </div>
  );
};
