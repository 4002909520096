import 'dayjs/locale/en';

import classNames from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { CiCalendar } from 'react-icons/ci';
import { IoIosCheckmark } from 'react-icons/io';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { formatDateString } from '../../utils/formatDateString';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface SDEntry {
  __typename?: 'Event' | undefined;
  id: number;
  name: string;
  type: string;
  subtype?: string | null | undefined;
  userId: number;
  programId: number;
  interactionId: number;
  payload: any;
  meta: any;
  createdAt: any;
  updatedAt: any;
}

interface Props {
  scheduledExercises: Date[][];
  selectedDay: Date;
  onSelect: (date: Date) => void;
  SDEntries?: { [k: string]: SDEntry };
}

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export const ToolCalendar: React.FC<Props> = ({
  scheduledExercises,
  selectedDay,
  onSelect,
  SDEntries,
}) => {
  const [scheduledWeekStart, setScheduledWeekStart] = useState(
    dayjs(scheduledExercises[0][0], 'MMMM/DD/YYYY')
  );
  const today = dayjs(new Date().toLocaleDateString());
  const [currentWeekStart, setCurrentWeekStart] = useState(scheduledWeekStart);
  const [showPrevNav, setShowPrevNav] = useState(false);

  useEffect(() => {
    if (scheduledExercises[0][0]) {
      setScheduledWeekStart(dayjs(scheduledExercises[0][0], 'MMMM/DD/YYYY'));
    }
  }, [scheduledExercises]);

  useEffect(() => {
    if (scheduledWeekStart) {
      setCurrentWeekStart(scheduledWeekStart);
    }
  }, [scheduledWeekStart]);

  useEffect(() => {
    let showPrev = false;
    if (currentWeekStart > scheduledWeekStart) {
      showPrev = true;
    }
    setShowPrevNav(showPrev);
  }, [currentWeekStart]);

  const getDayState = (date: Date) => {
    const foundEntry = SDEntries && SDEntries[formatDateString(date)];
    return !!foundEntry;
  };

  const isRecommended = (date: Date) => {
    // console.log("days recom", scheduledExercises)
    const foundDay = scheduledExercises[0].find((day) =>
      dayjs(day, 'MMMM/DD/YYYY').isSame(date, 'day')
    );
    return !!foundDay;
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < 7; i += 1) {
      const currentDay = currentWeekStart.add(i, 'day');
      const isSelected = dayjs(currentDay.toDate()).isSame(selectedDay, 'day');
      const isCompleted = getDayState(currentDay.toDate());
      const isPast = dayjs(currentDay.toDate()).isBefore(dayjs(), 'day');

      days.push(
        <div key={i} className="flex flex-col items-center">
          <span className="text-gray-600">{daysOfWeek[currentDay.day()]}</span>
          <div
            className={classNames(
              `flex h-7 w-7 items-center justify-center rounded-full`,
              isRecommended(currentDay.toDate())
                ? 'rounded-full border border-black'
                : 'text-black',
              {
                'bg-blue-500 text-white': isSelected,
                'bg-black text-white': isPast,
              },
              currentDay <= today ? 'cursor-pointer' : 'cursor-default'
            )}
            onClick={() => {
              if (currentDay <= today) {
                onSelect(currentDay.toDate());
              }
            }}
          >
            {currentDay.date()}
          </div>
          <IoIosCheckmark
            className={classNames(
              'ml-1 text-3xl text-green-500',
              isCompleted ? 'visible' : 'invisible'
            )}
          />
        </div>
      );
    }
    return days;
  };

  const handlePrevWeek = () => {
    if (showPrevNav) {
      setCurrentWeekStart(currentWeekStart.subtract(1, 'week'));
    }
  };

  const handleNextWeek = () => {
    setCurrentWeekStart(currentWeekStart.add(1, 'week'));
  };

  return (
    <div className="mx-10 max-w-md rounded-2xl border border-stone-300 bg-white p-4">
      {scheduledWeekStart && (
        <div>
          <div className="mb-4 flex items-center justify-between">
            <MdChevronLeft
              size={24}
              className={classNames(
                'cursor-pointer',
                showPrevNav ? '' : 'invisible'
              )}
              onClick={handlePrevWeek}
            />

            <div className="flex items-center">
              <span className="mr-1 text-xl font-semibold text-gray-700">
                {currentWeekStart
                  .locale('en')
                  .format('MMMM YYYY')
                  .toLocaleUpperCase()}
              </span>
              <CiCalendar size={24} />
            </div>
            <MdChevronRight
              size={24}
              className="cursor-pointer"
              onClick={handleNextWeek}
            />
          </div>
          <div className="grid grid-cols-7 gap-2">{renderDays()}</div>
        </div>
      )}
    </div>
  );
};
