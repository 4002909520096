import type { Node } from '@markdoc/markdoc';

export function extractTextFromNode(node: Node | Node[]): string {
  if (!node) {
    return '';
  }
  if (typeof node === 'string') {
    return node;
  }
  // Base case: if the node is actually an array of nodes, process each node in the array
  if (Array.isArray(node)) {
    return node.map(extractTextFromNode).join('');
  }

  // If the node is a text node, return its content
  if (node.type === 'text') {
    return (node as any).value;
  }

  // If the node has children, recursively process them and return the concatenated result
  if ((node as any).props.children && (node as any).props.children.length) {
    if (typeof (node as any).props.children === 'string') {
      return (node as any).props.children;
    }
    return (node as any).props.children.map(extractTextFromNode).join('');
  }

  // For nodes that don't contain text directly or have children (e.g., self-closing tags), return an empty string
  return '';
}
