import 'react-phone-number-input/style.css';

import { useSessionViewerState } from '@drugfreesleep/components/sessionViewerProvider/useSessionViewerState';
import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import type { ConfigType } from '@markdoc/markdoc';
import { Alert, Button, Label } from 'flowbite-react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { IoArrowForward } from 'react-icons/io5';
import PhoneInput from 'react-phone-number-input/input';

import { useAuth } from '../authentication/useAuth';
import { useVerifyMobile } from '../authentication/useVerifyMobile';
import { useSetMobileMutation } from '../graphql/generated-types';
import { useFormState } from './useFormState';

export const VerifyMobileConfig: ConfigType = {
  tags: {
    verifyMobile: {
      render: 'VerifyMobile',
      attributes: {
        name: {
          type: String,
          required: true,
        },
        required: {
          type: Boolean,
          required: false,
        },
      },
    },
  },
};

export interface VerifyMobileProps {
  name: string;
  required?: boolean;
}

export const VerifyMobile = ({ name, required }: VerifyMobileProps) => {
  const auth = useAuth();
  const router = useRouter();
  const {
    verify,
    loading: verifyLoading,
    error: verifyError,
  } = useVerifyMobile();
  const [setMobile, { loading: setLoading, error: setError }] =
    useSetMobileMutation();
  const [mobileNumber, setMobileNumber] = useState('');
  const [tokenKey, setTokenKey] = useState('');
  const [id, setId] = useState<null | string>(null);
  const programSlug = useProgramSlug();

  const [showSetError, setShowSetError] = useState(false);
  const [showVerifyError, setShowVerifyError] = useState(false);

  const { index: pathIndex } = useFormState('verifyMobile', name);

  const { setDisableNext, setEnableNext } = useSessionViewerState(
    name,
    pathIndex
  );

  useEffect(() => {
    if (required) {
      setDisableNext();
    }
  }, []);

  useEffect(() => {
    if (setError) {
      setShowSetError(true);
    }
    if (verifyError) {
      setShowVerifyError(true);
    }
  }, [setError, verifyError]);

  if (auth.mobileVerified) {
    return <></>;
  }

  const handleVerify = async (args: { id: string; tokenKey: string }) => {
    try {
      const scopedMobile = await verify(args);
      // setVerified(scopedMobile as string);
      if (scopedMobile && !verifyError) {
        // router.push(`/p/${programSlug}/today`);
        setEnableNext();
      }
    } catch {
      // handled by error
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log({ id, tokenKey });
    if (id && id.length > 0 && tokenKey && tokenKey.length === 4) {
      await handleVerify({ id, tokenKey });
    }
  };

  const handleSetMobile = async (newMobileNumber: string) => {
    const data = await setMobile({
      variables: {
        mobile: newMobileNumber,
        domain: window.location.origin,
      },
    });
    setId(data.data?.setMobile?.id as string);
  };

  const handleMobileSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (mobileNumber) {
      handleSetMobile(mobileNumber);
    }
  };

  const handleMobileChange = (newMobileNumber: unknown) => {
    setMobileNumber(newMobileNumber as string);
    // Hide alert messages when user starts typing
    setShowSetError(false);
  };

  const handleCodeChange = (e: any) => {
    setTokenKey(e.target.value);
    // Hide alert messages when user starts typing
    setShowVerifyError(false);
  };

  return (
    <div className="mx-auto flex h-screen w-5/6 flex-col md:flex-row md:flex-nowrap">
      {id ? (
        <div className="my-24 w-full md:w-1/2">
          <div className="mb-3 text-center text-2xl font-semibold text-black md:text-3xl">
            Enter Verification Code
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col">
              <div className="mb-2 block">
                <Label
                  style={{ color: 'black' }}
                  htmlFor="otp"
                  value="Your code"
                />
              </div>
              <input
                id="otp"
                name="otp"
                type="tel"
                inputMode="numeric"
                autoComplete="one-time-code"
                maxLength={4}
                required
                onChange={handleCodeChange}
              />
              <div className="flex w-full justify-center">
                <div className="absolute bottom-12 flex flex-col justify-center ">
                  <div className="mb-8 flex w-full justify-center">
                    <Button
                      style={{
                        width: '170px',
                        borderRadius: '2rem',
                        backgroundColor: 'rgb(30 64 175)',
                      }}
                      className="bg-blue-800"
                      type="submit"
                      disabled={verifyLoading || tokenKey.length !== 4}
                    >
                      <div className="mr-1">Verify</div>
                      <IoArrowForward />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              {showVerifyError && verifyError && (
                <Alert className="mt-4" color="warning">
                  {verifyError.message}
                </Alert>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className="my-24 w-full md:w-1/2">
          <div className="mb-5 text-center text-2xl font-semibold text-black md:text-3xl">
            {/* Enter Your Email */}
            Enter Your Mobile Number
          </div>
          <form
            className="flex flex-col md:mx-16"
            onSubmit={handleMobileSubmit}
          >
            <div>
              <div className="flex flex-col justify-center">
                <div className="mb-5 flex justify-center">
                  <div className="text-xs text-black md:text-sm">
                    We use your mobile number for account recovery and to send
                    you important reminders about the program.
                  </div>
                </div>
                <div className="flex justify-center">
                  <PhoneInput
                    className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700"
                    id="mobile"
                    type="mobile"
                    name="mobile"
                    style={{ maxWidth: '600px', minWidth: '350px' }}
                    country="US"
                    value={mobileNumber}
                    onChange={handleMobileChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full justify-center">
              <div className="absolute bottom-12 flex flex-col justify-center ">
                <div className="mb-8 flex w-full justify-center">
                  <Button
                    style={{
                      width: '170px',
                      borderRadius: '2rem',
                      backgroundColor: 'rgb(30 64 175)',
                    }}
                    className="bg-blue-800"
                    type="submit"
                    disabled={!mobileNumber || setLoading}
                  >
                    Next
                    <IoArrowForward />
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-2 text-center align-baseline text-black">
              <div
                className="cursor-pointer underline"
                onClick={() =>
                  router.push(
                    programSlug !== undefined
                      ? `/p/${programSlug}/today`
                      : `/today`
                  )
                }
              >
                Skip for now
              </div>
            </div>
            {showSetError && setError && (
              <Alert className="mt-4" color="warning">
                {setError.message}
              </Alert>
            )}
          </form>
        </div>
      )}
    </div>
  );
};
