import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import React, { useState } from 'react';

export const FiveSenseMethodConfig: ConfigType = {
  tags: {
    fiveSenseMethod: {
      render: 'FiveSenseMethod',
      attributes: {
        name: {
          type: String,
          required: false,
        },
      },
    },
  },
};

interface FiveSenseMethodProps {
  name?: string;
}

const senses = [
  { label: '5 things you can see', inputCount: 5 },
  { label: '4 things you can touch', inputCount: 4 },
  { label: '3 things you can hear', inputCount: 3 },
  { label: '2 things you can smell', inputCount: 2 },
  { label: '1 thing you can taste', inputCount: 1 },
];

export const FiveSenseMethod: React.FC<FiveSenseMethodProps> = ({ name }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [inputs, setInputs] = useState(
    senses.map((sense) => Array(sense.inputCount).fill(''))
  );

  const handleInputChange = (
    senseIndex: number,
    inputIndex: number,
    value: string
  ) => {
    const newInputs = [...inputs];
    newInputs[senseIndex][inputIndex] = value;
    setInputs(newInputs);
  };

  const next = () => {
    setCurrentIndex((prevVar) => (prevVar + 1) % senses.length);
  };

  const prev = () => {
    setCurrentIndex((prevVar) => (prevVar - 1 + senses.length) % senses.length);
  };

  return (
    <div className="mx-auto w-full max-w-md p-4" id={name}>
      <div className="mb-4 text-center text-xl font-semibold">
        {senses[currentIndex].label}
      </div>
      [audio input?]
      <div style={{ height: '500px' }}>
        {inputs[currentIndex].map((value, index) => (
          <input
            key={index}
            type="text"
            value={value}
            onChange={(e) =>
              handleInputChange(currentIndex, index, e.target.value)
            }
            placeholder={`${index + 1}`}
            // placeholder={`Enter ${senses[currentIndex].label.toLowerCase()}`}
            className="mb-2 block w-full rounded-md border p-2"
          />
        ))}
      </div>
      <div className="mt-4 flex justify-between">
        <button
          onClick={prev}
          className={classNames(
            'px-4 py-2 bg-blue-500 text-white rounded-md w-24',
            currentIndex === 0 && 'opacity-50 cursor-not-allowed'
          )}
          disabled={currentIndex === 0}
        >
          Previous
        </button>
        {currentIndex === senses.length - 1 ? (
          <button
            onClick={next}
            className={classNames(
              'px-4 py-2 bg-blue-500 text-white rounded-md w-24',
              currentIndex === senses.length - 1 &&
                'opacity-50 cursor-not-allowed'
            )}
            disabled={currentIndex === senses.length - 1}
          >
            Next
          </button>
        ) : (
          <button
            onClick={() => {
              console.log('what to do on finish?');
            }}
            className={classNames(
              'px-4 py-2 bg-blue-500 text-white rounded-md w-24',
              currentIndex === senses.length - 1 &&
                'opacity-50 cursor-not-allowed'
            )}
          >
            Finish?
          </button>
        )}
      </div>
    </div>
  );
};
